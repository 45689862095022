import React, { useState } from 'react';

function TruncatedText({ str, maxLength }) {
  const [showFullText, setShowFullText] = useState(false);

  const handleClick = () => {
    setShowFullText(!showFullText);
  };

  if (str.length > maxLength && !showFullText) {
    return (
      <div>
        {str.slice(0, maxLength - 3)}...{' '}
        <span onClick={handleClick} style={{ cursor: 'pointer', color: 'blue' }}>
          More
        </span>
      </div>
    );
  }

  if (str.length > maxLength && showFullText) {
    return (
      <div>
        {str}{' '}
        <span onClick={handleClick} style={{ cursor: 'pointer', color: 'blue' }}>
          Less
        </span>
      </div>
    );
  }

  return <div>{str}</div>;
}

export default TruncatedText;
