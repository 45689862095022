const actionTypes = {
  createPaymentLink: {
    api: '/payment/create-payment-link'
  },
  updateApiKeys: {
    api: '/update-api-keys'
  },
  getUser: {
    api: '/get-user'
  },
  createUser: {
    api: '/create-user'
  },
  deleteProject: {
    api: '/delete-project',
    confirmationText: 'Are you sure you want to delete this project?'
  },
  getProjects: {
    api: '/get-projects'
  },
  createProject: {
    api: '/create-project'
  },
  getProject: {
    api: '/get-project'
  },
  search: {
    api: '/search'
  },
  analyzePost: {
    api: '/analyze-post'
  },
  saveSearchResult: {
    api: '/save-search-result'
  },
  getSavedSearchResults: {
    api: '/get-saved-search-results'
  },
  removeResult: {
    api: '/remove-result'
  },
  analyzeSavedSearchResults: {
    api: '/analyze-results'
  },
  deleteSavedSearch: {
    api: '/delete-saved-search'
  },
  updateProjectDetails: {
    api: '/update-project-details'
  },
  getSearchSchedule: {
    api: '/get-search-schedule'
  },
  createSearchSchedule: {
    api: '/create-search-schedule'
  },
  getProjectSavedSearch: {
    api: '/get-project-saved-search'
  }
}

export {
  actionTypes
};
