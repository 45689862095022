import React, { useEffect } from 'react';
import { message } from 'antd';

import "./errorMessage.css";

// const errMsg = `
// We are currently experiencing high traffic and are working to resolve the issue. Due to this, trial accounts have been temporarily disabled, and current users may encounter problems with course creation using tokens. We will notify you once the issue has been resolved.
//   `

// const upgradeMsg = `Server Upgrade Alert: Some services may be temporarily unavailable. We apologize for any inconvenience and appreciate your patience. Thank you!`

// const MaintenancePage = () => <div>Our website is under maintenance, sorry for the inconvenience. Please check back later.</div>

const ErrorMsg = ({ error, onCloseError }) => {
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (error) {
      const msg = error?.response?.data?.message
        || error?.response?.data?.data?.message
        || error?.response?.data?.sqlMessage
        || error.message
        || error.error

      messageApi.open({
        type: error.type || 'error',
        content: <span style={{ color: 'black' }}>{msg}</span>,
        duration: 0,
        onClick: () => {
          if (onCloseError) {
            onCloseError()
          }
          messageApi.destroy();
        },
        className: "errorMsg"
      });
    }
  }, [error?.timestamp, messageApi])

  return (
    <>{contextHolder}</>
  );
}

export default ErrorMsg;
