
import ProtectPage from './components/ProtectPage';
import Page from "./components/Page";

import Signin from './pages/auth/Auth';
import AccountPage from './pages/account/Account';
import SupportPage from './pages/support/Support'
import Pricing from './pages/pricing/Pricing';

import Project from "./pages/project/Project.jsx";
import ProjectsPage from "./pages/projects/Projects.jsx";
import AddNewProjectPage from "./pages/projects/AddNewProject.jsx";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';

const Redirect = () => {
  const history = useHistory()

  return history.push('/user/projects')
}

const routeConfig = [{
  route: "/",
  components: [
    { component: Redirect, props: { exact: true } }
  ]
}, {
  route: "/signin",
  components: [
    { component: Signin, props: { exact: true } }
  ]
}, {
  route: "/user/account",
  components: [
    ProtectPage,
    { component: Page, props: { initialActions: ["getUser"] } },
    { component: AccountPage, props: { exact: true } }
]}, {
  route: "/pricing",
  components: [
    { component: Page },
    { component: Pricing, props: { exact: true } }
  ]
}, {
  route: "/support",
  components: [
    { component: SupportPage, props: { exact: true } }
  ]
}, {
  route: "/user/projects",
  components: [
    ProtectPage,
    { component: Page },
    { component: ProjectsPage, props: { exact: true } }
]}, {
  route: "/user/project/new",
  components: [
    ProtectPage,
    Page,
    { component: AddNewProjectPage, props: { exact: true } }
]}, {
  route: "/user/project/:projectId",
  components: [
    ProtectPage,
    { component: Page },
    { component: Project, props: { exact: true } }
]}]

export default routeConfig;
