import { Button, Card, Tag } from "antd";
import React, { useEffect } from "react";
import { LeftOutlined } from '@ant-design/icons';

import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from "../../utils/fetch.js";
import { convertToLocaleTime } from "../../utils/date.js"
import { actionTypes } from "../../actions";
import { scrapeTime, siteToScrape } from "../../constants";
import TruncatedText from "../../components/TruncatedText.jsx";

const SearchResultItem = React.memo(({ item, removeResult }) => {
  return (
    <Card key={item.link} size="small" style={{ padding: '0 16px' }}>
      {
        item.status &&
        (
          <div style={{ display: 'flex', marginBottom: 15 }}>
            {
              item.status === 2 &&
              (
                <>
                  <Tag color={item.relevance_score > 5 ? 'green' : undefined}>Relevance score - {item.relevance_score}</Tag>
                  <Tag>Engagement opportunity - {item.engagement_opportunity}</Tag>
                </>
              )
            }
            {
              item.status === 1 &&
              (
                <Tag color="blue">Analyzing</Tag>
              )
            }
          </div>
        )
      }
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <a style={{ fontSize: 17, fontWeight: 500 }} href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <span>{new URL(item.link).hostname}</span>
            <p style={{ margin: 0 }}>{item.snippet}</p>
            {item.post_content && <p style={{ margin: 0 }}>{<TruncatedText str={item.post_content} maxLength={window.innerWidth > 500 ? 400 : 200} />}</p>}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Button type="link" onClick={() => { removeResult(item.id) }}>Remove</Button>
        </div>
      </div>
    </Card>
  );
});

const SavedSearch = ({ project }) => {
  const { setError } = React.useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [showResults, setShowResults] = React.useState(false);
  const [viewResults, setViewResults] = React.useState(null);
  const [selectedResult, setSelectedResult] = React.useState(null);
  const [savedSearch, setSavedSearch] = React.useState(null);

  useEffect(() => {
    getProjectSavedSearch()
  }, [])

  const getSavedSearchResults = async (item) => {
    const action = "getSavedSearchResults";
    setShowResults(true);
    setViewResults([]);
    addAction(action);
    setSelectedResult(item)
    return await post(actionTypes[action].api, {
      projectId: project.id,
      savedSearchId: item.id
    })
      .then((res) => {
        setViewResults(res.data.results)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const getProjectSavedSearch = async () => {
    const action = "getProjectSavedSearch";
    addAction(action);
    await post(actionTypes[action].api, {
      projectId: project.id,
    })
      .then((res) => {
        setSavedSearch(res.data.savedSearch)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const removeResult = async (id) => {
    const action = "removeResult";
    addAction(action);
    await post(actionTypes[action].api, {
      resultId: id,
      projectId: project.id
    })
      .then((res) => {
        const updatedViewResults = [...viewResults].filter((item) => item.id !== id)
        setViewResults(updatedViewResults)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const analyzeSavedSearchResults = async (id) => {
    const action = "analyzeSavedSearchResults";
    addAction(action);
    await post(actionTypes[action].api, {
      savedSearchResultId: id,
      projectId: project.id
    })
      .then(async (res) => {
        await getSavedSearchResults(selectedResult)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const deleteSavedSearch = async (id) => {
    const action = "deleteSavedSearch";
    addAction(action);
    await post(actionTypes[action].api, {
      id,
      projectId: project.id
    })
      .then((res) => {
        const newSavedSearch = [...savedSearch].filter((item) => item.id !== id);
        setSavedSearch(newSavedSearch);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      {
        !showResults && savedSearch?.map((item) => (
          <Card key={item.id} size="small">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', gap: 10 }}>
                <span>{siteToScrape[item.metadata.site]}</span>
                <span>{scrapeTime[item.metadata.time]}</span>
              </div>
              <span>{convertToLocaleTime(item.created_date)}</span>
            </div>
            <h3 style={{ fontSize: 18 }}>{item.metadata.textQuery}</h3>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Tag>{`${item.results.length} results`}</Tag>
                {
                  item['6relevanceScore'] > 0 && <Tag color="green">{`${item['6relevanceScore']} relevant post found`}</Tag>
                }
                <Tag color={item['status1Count'] > 0 ? "blue" : undefined}>{`${item['status1Count']} scheduled for analyzing`}</Tag>
              </div>
              <div style={{ display: 'flex', gap: 10 }}>
                <Button type="link" size="small" onClick={() => { getSavedSearchResults(item) }}>View</Button>
                <Button type="link" size="small" onClick={() => { deleteSavedSearch(item.id) }}>Delete</Button>
              </div>
            </div>
          </Card>
        ))
      }
      {
        showResults &&
        (
          <div>
            <Card size="small" style={{ marginBottom: 5 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <Button
                    icon={<LeftOutlined />}
                    onClick={async () => {
                      await getProjectSavedSearch()
                      setShowResults(false)
                    }}
                    type="ghost"
                  />
                  <span style={{ fontWeight: 500 }}>{`${siteToScrape[selectedResult.metadata.site]} - ${selectedResult.metadata.textQuery} - ${scrapeTime[selectedResult.metadata.time]} - ${convertToLocaleTime(selectedResult.created_date)}`}</span>
                </div>
                <div>
                  <Button onClick={() => { analyzeSavedSearchResults(selectedResult.id) }} type="primary">Start analyze</Button>
                </div>
              </div>
            </Card>
            <div style={{ display: 'flex', gap: 5, flexDirection: 'column', overflow: 'auto', height: 'calc(100vh - 170px)' }}>
              {
                viewResults?.map((item) => {
                  return (
                    <SearchResultItem
                      key={item.link}
                      item={item}
                      removeResult={removeResult}
                    />
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default SavedSearch;
