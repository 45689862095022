import React from 'react';
import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import "./index.css";

const ProjectCard = ({ project, deleteProject }) => {
  const history = useHistory();

  return (
    <Card size='small'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: window.innerWidth > 600 ? 'row' : 'column', gap: 20 }}>
          <span>{project.name}</span>
          <span style={{ display: 'flex', justifyContent: 'end', gap: 5 }}>
            <Button
              size='small'
              onClick={() => { history.push(`/user/project/${project.id}`) }}
            >
              View
            </Button>
            <Button
              size='small'
              onClick={() => { deleteProject(project.id) }}
            >
              Delete
            </Button>
          </span>
        </div>
      </div>
    </Card>
  )
}

export default ProjectCard;
