import { Card, Tag } from "antd";
import React from "react";

const SearchResultItem = React.memo(({ item }) => {
  return (
    <Card key={item.link} size="small">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <a style={{ fontSize: 17 }} href={item.link} target="_blank" rel="noreferrer">{item.title}</a>
          <div style={{ display: 'flex', gap: 5, marginTop: 5, flexDirection: window.innerWidth > 500 ? 'row' : 'column' }}>
            <Tag style={{ width: 'fit-content', height: 'fit-content' }}>{new URL(item.link).hostname}</Tag>
            <p style={{ margin: 0 }}>{item.snippet}</p>
          </div>
        </div>
      </div>
    </Card>
  );
});

const SearchResults = ({ results }) => {
  return (
    <div style={{ marginTop: 10, overflow: 'auto', height: 'calc(100vh - 340px)', display: 'flex', flexDirection: 'column', gap: 3 }}>
      {
        results
          .filter((item) => !!item)
          .map((item) => (
          <SearchResultItem
            key={item.link}
            item={item}
          />
        ))
      }
    </div>
  );
};

export default SearchResults;
