function isValidUrl(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  return !!pattern.test(str);
}

const splitIntoSentencesAndCodeBlocks = (input) => {
  const regex = /(```[\s\S]*?```)/g;
  const fileNameRegex = /Here's the updated source code for ([a-zA-Z0-9_.-]*):(?=\n```)/g;
  const matches = input.split(regex);
  let fileNameMatches = input.match(fileNameRegex);

  if (fileNameMatches !== null) {
    fileNameMatches = fileNameMatches.map((match) => match.slice(35, -1));
  }

  return matches.map((match, index) => {
    const isCode = match.startsWith('```') && match.endsWith('```');
    let sentence = isCode ? match.slice(3, -3).trim() : match;
    
    if (isCode && fileNameMatches !== null) {
      const codeLines = sentence.split('\n');
      codeLines.shift(); // remove the first line (language type)
      sentence = codeLines.join('\n');
    }

    return {
      sentence,
      isCode,
      fileName: isCode && fileNameMatches !== null ? fileNameMatches.shift() : null,
    };
  }).filter((item) => item.sentence.trim() !== '');
};

const filterDuplicateArrayItem = (array=[], propertyToCheck) => {
  const uniqueLinks = new Set();

  return array.filter((item) => {
    if (uniqueLinks.has(item[propertyToCheck])) {
      return false;
    }
    uniqueLinks.add(item[propertyToCheck]);
    return true;
  });
};

function truncateString(str="", maxLength) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + '...';
  }
  return str;
}

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function abbreviateNumber(num, returnObject=false) {
  var sizes = ['k', 'm', 'b', 't'];
  if (num < 1000) return num;
  var i = parseInt(Math.floor(Math.log(num) / Math.log(1000)));

  if (returnObject) {
    return ({
      num: (num / Math.pow(1000, i)).toFixed(1),
      size: sizes[i - 1]
    })
  }

  return (num / Math.pow(1000, i)).toFixed(1) + sizes[i - 1];
}

function parseAbbreviateNumber(formattedNum) {
  var sizes = { 'k': 1000, 'm': 1000000, 'b': 1000000000, 't': 1000000000000 };
  var re = /^(\d+(?:\.\d+)?)([kmbt])$/i;
  var match = re.exec(formattedNum);

  if (!match) throw new Error("Invalid input format");

  var num = parseFloat(match[1]);
  var size = match[2].toLowerCase();

  return num * sizes[size];
}

function convertSnakeToCamelCase(str) {
  return str.replace(/_\w/g, function(m) {
      return m[1].toUpperCase();
  });
}

export {
  convertSnakeToCamelCase,
  isValidUrl,
  splitIntoSentencesAndCodeBlocks,
  filterDuplicateArrayItem,
  truncateString,
  capitalizeFirstLetter,
  abbreviateNumber,
  parseAbbreviateNumber
}