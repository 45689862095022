import { Button, Checkbox, Input, Select } from "antd";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from "../../utils/fetch";
import SearchResults from "./SearchResults.jsx";

const InstantSearch = ({ project, getProject }) => {
  const { setError, isActionInProgress } = React.useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { projectId } = useParams();
  const [useSavedKeywords, setUseSaveKeywords] = React.useState(true);
  const [time, setTime] = React.useState('anytime');
  const [selectedKeyword, setSelectedKeyword] = React.useState(project.keywords[0].id);
  const [site, setSite] = React.useState('all');
  const keywordInputRef = React.useRef();
  const [searchResults, setSearchResults] = React.useState(project.current_results?.results || [])

  const search = async (startIndex=0) => {
    if (!startIndex) {
      setSearchResults([])
    }
    const action = "search";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId,
      startIndex,
      time,
      textQuery: useSavedKeywords
        ? project.keywords.find((item) => item.id === selectedKeyword).name
        : keywordInputRef.current.input.value,
      site
    })
      .then((res) => {
        setSearchResults(
          startIndex
            ? [...searchResults, ...res.data.results]
            : res.data.results
        )
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const saveSearchResult = async () => {
    const action = "saveSearchResult";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId
    })
      .then((res) => {
        setSearchResults([])
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div style={{ display: 'flex', gap: 20 }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Checkbox
          value={useSavedKeywords}
          onChange={() => {
            setUseSaveKeywords(!useSavedKeywords)
            setSelectedKeyword(null);
          }}
          style={{ display: 'flex', alignSelf: 'end' }}
          checked={useSavedKeywords}
        >
          Use saved keywords
        </Checkbox>
        {
          useSavedKeywords ?
          (
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select keyword"
              value={selectedKeyword}
              onChange={(val) => { setSelectedKeyword(val) }}
              options={[...project.keywords].map((item) => ({
                value: item.id,
                label: item.name
              }))}
            />
          ) :
          (
            <Input ref={keywordInputRef} />
          )
        }
        <Select
          style={{
            width: '100%',
          }}
          value={time}
          placeholder="Select time"
          onChange={(val) => { setTime(val) }}
          options={[
            {
              value: 'anytime',
              label: 'Any time'
            },
            {
              value: 'd1',
              label: 'Past 24 hours'
            },
            {
              value: 'w1',
              label: 'Past week'
            },
            {
              value: 'm1',
              label: 'Past month'
            },
            {
              value: 'y1',
              label: 'Past year'
            }
          ]}
        />
        <Select
          style={{
            width: '100%',
          }}
          value={site}
          placeholder="Select site"
          onChange={setSite}
          options={[
            {
              value: 'all',
              label: 'Search all site'
            },
            {
              value: 'reddit',
              label: 'Reddit'
            },
            {
              value: 'quora',
              label: 'Quora'
            },
            {
              value: 'twitter',
              label: 'Twitter (Coming soon!)',
              disabled: true
            },
          ]}
        />
        <Button disabled={isActionInProgress(["search"])} onClick={() => { search() }}>Search</Button>
        {
          searchResults.length > 0 &&
          (
            <SearchResults results={searchResults} saveSearchResult={saveSearchResult} />
          )
        }
        {
          searchResults.length > 0 &&
          (
            <div style={{ display: 'flex', gap: 5, justifyContent: 'flex-end', alignItems: 'baseline' }}>
              <span style={{ margin: '0 10px' }}>{searchResults.length} results</span>
              <Button disabled={isActionInProgress(["search"])} onClick={() => { search(searchResults.length) }}>More</Button>
              <Button disabled={isActionInProgress(["saveSearchResult"])} onClick={saveSearchResult}>Save to bucket</Button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default InstantSearch;
