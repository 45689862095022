import { message } from 'antd';
import { useEffect, useState } from 'react';

import "./errorMessage.css";

const LoadingMessage = ({ fetchItems, style={} }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fetchItems.length > 0) {
      messageApi.open({
        type: 'loading',
        content: fetchItems[0].msg || 'Action in progress..',
        duration: 0,
        style,
      });
      setIsLoading(true);
    } else if (fetchItems.length === 0 && isLoading) {
      messageApi.destroy();
      setIsLoading(false);
    }
  }, [fetchItems]);

  return contextHolder;
};

export default LoadingMessage;
