import { useHistory } from 'react-router-dom';

const ProtectPage = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken")
  const history = useHistory();

  if (!accessToken) {
    history.push("/signin");
    return;
  }

  return children;
}

export default ProtectPage