import { Input } from "antd";
import React from 'react';
import { Button, Card, Form } from "antd";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from "../../utils/fetch";

const KeywordInput = ({ keywords, onSubmit }) => {
  const { isActionInProgress } = React.useContext(PageContext);
  const [form] = Form.useForm();

  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          items: keywords
        }}
        form={form}
      >
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 40 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <label>Keywords</label>
                  <Button type="link" onClick={() => add()}>Add new</Button>
                </div>
                <div style={{ display: 'flex', gap: 5, flexDirection: 'column', width: '100%' }}>
                  {
                    fields.map((item) => {
                      return (
                        <div key={item.key} style={{ display: 'flex', gap: 5 }}>
                          <Card size='small' style={{ width: '100%' }}>
                            <Form.Item noStyle name={[item.name, 'name']}>
                              <Input
                                size='small'
                                variant="borderless"
                              />
                            </Form.Item>
                          </Card>
                          <Card size='small'>
                            <div style={{ display: 'flex', gap: 5 }}>
                              <Button size='small' type='link' onClick={() => { remove(item.name); }}>Delete</Button>
                            </div>
                          </Card>
                        </div>
                      )
                    })
                  }
                </div>
                <Button
                  onClick={() => { onSubmit(form.getFieldsValue().items) }}
                  style={{ marginTop: 20 }}
                  size="large"
                  disabled={isActionInProgress("updateProjectDetails")}
                >
                  Save
                </Button>
              </div>
            )
          }}
        </Form.List>
      </Form>
    </div>
  )
}

const ProjectDetails = ({ project, getProject }) => {
  const { setError } = React.useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { projectId } = useParams();
  const [form] = Form.useForm();

  const updateDetails = async (keywordInput) => {
    const values = form.getFieldsValue()
    const action = "updateProjectDetails";
    addAction(action);
    await post(actionTypes[action].api, {
      projectId,
      description: values.description,
      keywords: keywordInput
    })
      .then(async (res) => {
        await getProject()
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div style={{ padding: 40, display: 'flex', flexDirection: 'column', maxWidth: 900, margin: 'auto' }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: project.name,
          description: project.description
        }}
      >
        <Form.Item name="name" label="Brand name">
          <Input disabled />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea
            rows={6}
            placeholder="The AI will analyze social media posts based on your project description to calculate relevance scores and engagement opportunities. Therefore, it is essential to concisely describe the essence of your project/business."
            maxLength={750}
            showCount={true}
          />
        </Form.Item>
        <KeywordInput keywords={project.keywords} onSubmit={updateDetails} />
      </Form>
    </div>
  )
}

export default ProjectDetails;
