import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { Button, Card, Checkbox, Input, Select, Tag } from 'antd';
import { siteToScrape } from '../../constants';

const Scheduler = ({ project }) => {
  const { setError } = React.useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { projectId } = useParams();
  const [searchSchedule, setSearchSchedule] = React.useState([]);
  const [useSavedKeywords, setUseSaveKeywords] = React.useState(true);
  const [selectedKeyword, setSelectedKeyword] = React.useState(project.keywords[0].id);
  const [site, setSite] = React.useState('all');
  const keywordInputRef = React.useRef();

  React.useEffect(() => {
    getSearchSchedule();
  }, [])

  const getSearchSchedule = async () => {
    const action = "getSearchSchedule";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId
    })
      .then((res) => {
        setSearchSchedule(res.data.searchSchedules)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  const createSearchSchedule = async () => {
    const action = "createSearchSchedule";
    addAction(action);
    return await post(actionTypes[action].api, {
      projectId,
      textQuery: useSavedKeywords
        ? project.keywords.find((item) => item.id === selectedKeyword).name
        : keywordInputRef.current.input.value,
      site
    })
      .then((res) => {
        const newSearchSchedules = [...searchSchedule, { ...res.data.schedule }];
        setSearchSchedule(newSearchSchedules)
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
      <Card style={{ backgroundColor: '#C3E3FF' }}>
        <h2>Scheduler</h2>
        <p style={{ margin: 0 }}>Scheduler will run every 12 hours & fetch the latest post about your keywords.</p>
      </Card>
      <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <Checkbox
            value={useSavedKeywords}
            onChange={() => {
              setUseSaveKeywords(!useSavedKeywords)
              setSelectedKeyword(null);
            }}
            style={{ display: 'flex', alignSelf: 'end' }}
            checked={useSavedKeywords}
          >
            Use saved keywords
          </Checkbox>
          <div style={{ display: 'flex', gap: 10, flexWrap: window.innerWidth > 600 ? undefined : 'wrap' }}>
            {
              useSavedKeywords ?
              (
                <Select
                  style={{
                    width: '100%',
                  }}
                  placeholder="Select keyword"
                  value={selectedKeyword}
                  onChange={(val) => { setSelectedKeyword(val) }}
                  options={[...project.keywords].map((item) => ({
                    value: item.id,
                    label: item.name
                  }))}
                />
              ) :
              (
                <Input ref={keywordInputRef} />
              )
            }
            <Select
              style={{
                width: '100%',
              }}
              value={site}
              placeholder="Select site"
              onChange={setSite}
              options={[
                {
                  value: 'all',
                  label: 'Search all site'
                },
                {
                  value: 'reddit',
                  label: 'Reddit'
                },
                {
                  value: 'quora',
                  label: 'Quora'
                }
              ]}
            />
            <Button onClick={createSearchSchedule}>Create</Button>
          </div>
        </div>
      </Card>
      <div style={{ height: 'calc(100vh - 350px)', display: 'flex', flexDirection: 'column', gap: 5 }}>
        {
          searchSchedule?.map((item) => (
            <Card size='small'>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 15px', alignItems: 'baseline' }}>
                <div>{item.keyword} - {siteToScrape[item.site_id]}</div>
                <div>
                  <Tag>Scheduled</Tag>
                  <Tag>0 result found</Tag>
                  <Button type='link'>Delete</Button>
                </div>
              </div>
            </Card>
          ))
        }
      </div>
    </div>
  )
}

export default Scheduler;
