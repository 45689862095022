import React from 'react';
import { Card } from 'antd';

import useScrollToTop from '../../utils/useScrollToTop';
import CommonPage from '../../components/CommonPage';

const Project = () => {
  useScrollToTop()

  return (
    <CommonPage title="Support">
      <Card>
        Please send an email to <a href="mailto:resz.mzahid@gmail.com">resz.mzahid@gmail.com</a> for any complaints, bug reports, suggestions, refund requests, or if you require assistance using the app.
      </Card>
    </CommonPage>
  );
}

export default Project;
