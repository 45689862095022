import { Button, Form, Input } from "antd";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { GoogleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import firebaseApp from "../../firebase.js";
import { post } from "../../utils/fetch"
import { useContext } from "react";
import useScrollToTop from "../../utils/useScrollToTop.js";

import { PageContext } from "./Auth.jsx";

const auth = getAuth(firebaseApp);

const provider = new GoogleAuthProvider();
const Register = ({ hideRegister }) => {
  useScrollToTop()
  const { handleRemoveFetchItems, handleSetFetchItems, showErrorMsg } = useContext(PageContext);
  const history = useHistory();
  const [form] = Form.useForm();

  const createUser = async (values) => {
    const api = `/create-user`;
    handleSetFetchItems(api);
    await post(api, { referrerUrl: localStorage.getItem('referrerUrl'), referral: values.referral })
      .then(async (response) => {
        history.push(`/user/project/new`)
      })
      .catch((err) => {
        showErrorMsg(err);
      })
    handleRemoveFetchItems(api);
  }

  const googleSignIn = (values) => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        localStorage.setItem('accessToken', user.accessToken);
        localStorage.setItem('email', user.email);
        await createUser(values);
      }).catch((err) => {
        showErrorMsg(err);
      });
  }

  const signUp = async (values) => {
    handleSetFetchItems('/signin/createUserWithEmailAndPassword');
    await createUserWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        localStorage.setItem('accessToken', user.accessToken);
        localStorage.setItem('email', user.email);
        await createUser(values);
      })
      .catch((error) => {
        showErrorMsg(error);
      });
    handleRemoveFetchItems('/signin/createUserWithEmailAndPassword');
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        flexDirection: 'column',
        height: 600,
        width: '100%',
      }}
    >
      <Form
        form={form}
        name="basic"
        style={{
          width: "100%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={hideRegister ? createUser : signUp}
        autoComplete="off"
      >
        <h3>How did you find us?</h3>
        <Form.Item
          name="referral"
        >
          <Input placeholder="Help us grow better by sharing how you stumbled upon us." />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.referral !== currentValues.referral}
        >
          {({ getFieldValue, values }) =>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              <>
                <div style={{ marginBottom: 20, display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                  <h3>Register</h3>
                  <Button
                    onClick={() => {
                      googleSignIn({ referral: values?.referral })
                    }}
                    type="primary"
                    icon={<GoogleOutlined />}
                  />
                </div>
                <Form.Item
                  label=""
                  name="email"
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="password"
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
              </>
            }
            <Form.Item
              style={{ marginLeft: 'auto' }}
            >
              <Button type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </div>
          }
        </Form.Item>
      </Form>
    </div>
  )
}

export default Register;
