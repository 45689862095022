import { Tabs } from "antd"
import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import Show from "../../components/Show";
import { PageContext, usePageActionHandler } from '../../components/Page';
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import InstantSearch from "./InstantSearch";
import SavedSearch from "./SavedSearch";
import ProjectDetails from "./ProjectDetails";
import Scheduler from "./Scheduler";
import MainMenu from "../../components/MainMenu";
import Logo from "../../components/Logo";

const Project = () => {
  const { setError } = React.useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("search")

  React.useEffect(() => {
    getProject();
  }, [])

  const getProject = async () => {
    const action = "getProject";
    addAction(action);
    setProject(null);
    return await post(actionTypes[action].api, {
      id: projectId
    })
      .then((res) => {
        setProject(res.data.project)
        return res.data.project
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div style={{ padding: 10 }}>
      <Tabs
        size="large"
        style={{ height: 'calc(100vh - 32px)' }}
        activeKey={activeTabKey}
        destroyInactiveTabPane
        tabPosition="top"
        type="card"
        onChange={async (key) => {
          if (key === 'search') {
            await getProject();
          }
          setActiveTabKey(key)
        }}
        tabBarExtraContent={{
          left: (
            <div style={{ marginRight: 10 }}>
              <Logo width={32} height={32} />
            </div>
          ),
          right: (
            <div style={{ padding: '0 10px' }}>
              <MainMenu />
            </div>
          )
        }}
        items={[
          {
            label: "Project Details",
            key: "details",
            children: activeTabKey === 'details' && (
              <Show show={project}>
                <ProjectDetails project={project} getProject={getProject} />
              </Show>
            )
          },
          {
            label: "Quick Search",
            key: "search",
            children: activeTabKey === 'search' && (
              <Show show={project}>
                <InstantSearch project={project} />
              </Show>
            )
          },
          {
            label: "Search Bucket",
            key: "saved",
            children: activeTabKey === 'saved' && (
              <Show show={project}>
                <SavedSearch project={project} />
              </Show>
            )
          },
          {
            label: "Scheduler",
            key: "scheduler",
            children: activeTabKey === 'scheduler' && (
              <Show show={project}>
                <Scheduler project={project} />
              </Show>
            )
          },
        ]}
      />
    </div>
  )
}

export default Project;
