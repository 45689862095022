import React, { useContext } from 'react';
import { Button, Card } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import useScrollToTop from '../../utils/useScrollToTop';
import { PageContext, usePageActionHandler } from '../../components/Page';
import { actionTypes } from '../../actions';
import { post } from '../../utils/fetch';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Logo from '../../components/Logo';

const plans = [
  {
    id: "v1_sub_starter",
    title: "Starter Plan",
    price: "25 / month",
    desc: "Begin your journey to social media excellence with our Starter Plan. Perfect for newcomers and small-scale users, this plan provides:",
    features: [
      "3 projects",
      "300 search queries / month",
      "Analyze up to 1,500 posts / month",
      "6000 AI credits (GPT-4) / month",
      "Search & analyze posts from Reddit & Quora",
      "(Coming soon) Search & analyze posts from Twitter",
    ],
    purchaseBtnText: "Subscribe"
  },
  {
    id: "v1_sub_premium",
    title: "Premium Plan",
    price: "45 / month",
    desc: "Unlock the full potential of our services with our Premium plan. Designed for users who require more extensive capabilities, this plan offers:",
    features: [
      "Unlimited projects",
      "600 search queries / month",
      "Analyze up to 3,000 posts / month",
      "12,000 AI credits (GPT-4) / month",
      "Access to a Scheduler for efficient planning",
      "Search & analyze posts from Reddit & Quora",
      "(Coming soon) Search & analyze posts from Twitter",
      "Use your own OpenAI API key (Optional)"
    ],
    purchaseBtnText: "Subscribe"
  }
]

const Pricing = () => {
  useScrollToTop()
  const history = useHistory()
  const {
    setError
  } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const userEmail = localStorage.getItem('email');

  const handleGenerateBuyTokenPaymentLink = async (productKey, value) => {
    const action = 'createPaymentLink';
    addAction(action);
    await post(actionTypes[action].api, {
      productKey,
      amount: value * 100,
      url: window.location.protocol + '//' + window.location.host
    })
      .then((res) => {
        window.location.replace(`${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`)
      })
      .catch((err) => {
        setError(err)
      })
      .finally(() => {
        removeAction(action);
      })
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '40px',
          gap: '20px'
        }}
      >
        <Link to="/user/projects"><Logo width={70} height={70} /></Link>
        <h1 style={{ maxWidth: 350, textAlign: 'center' }}>Start your journey to better engagement.</h1>
        <Button
          size='large'
          onClick={() => {
            if (localStorage.getItem("accessToken")) {
              history.push("/user/projects")
            } else {
              history.push("/signin")
            }
          }}
        >
          <span style={{ margin: 'auto' }}>Try now</span>
        </Button>
      </div>
      <div style={{ display: 'flex', gap: 10, justifyContent: 'center', flexWrap: window.innerWidth > 1080 ? undefined : 'wrap', marginBottom: 20 }}>
        {
          plans.map((plan) => (
            <Card style={{ width: '100%', height: '100%', minWidth: 300, maxWidth: 360, border: '1px solid #E5E5E5', padding: 16 }}>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <div key={plan.id}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
                    <h2>{plan.title}</h2>
                    <span style={{ fontSize: 20 }}>${plan.price}</span>
                  </div>
                  <p style={{ color: '#6B6B6B' }}>{plan.desc}</p>
                  <div style={{ display: 'flex', gap: 5, flexDirection: 'column', margin: '26px 0' }}>
                    <Button
                      size='large'
                      onClick={() => {
                        if (localStorage.getItem("accessToken")) {
                          handleGenerateBuyTokenPaymentLink(plan.id)
                        } else {
                          history.push("/")
                        }
                      }}
                      style={{ backgroundColor: '#1A8917', color: 'white', display: 'flex',  width: '100%' }}
                    >
                      <span style={{ margin: 'auto' }}>{plan.purchaseBtnText}</span>
                    </Button>
                  </div>
                  <div style={{ width: '100%', backgroundColor: '#E5E5E5', height: 1, margin: '26px 0' }} />
                    {
                      plan.features.map((feature) => (
                        <div style={{ display: 'flex', gap: 10, margin: 5 }}>
                          <CheckOutlined style={{ color: 'green' }} />
                          <span>{feature}</span>
                        </div>
                      ))
                    }
                  </div>
              </div>
            </Card>
          ))
        }
      </div>
    </div>
  );
}

export default Pricing;