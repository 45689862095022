const planType = {
  "default": "default"
};

const ERRORS = {
  "pleaseUpgrade": "Upgrade your plan to use this feature."
}

const siteToScrape = {
  all: 'All site',
  reddit: 'Reddit',
  quora: 'Quora'
}

const scrapeTime = {
  anytime: 'Any time',
  d1: 'Past 24 hours',
  w1: 'Past week',
  m1: 'Past month',
  y1: 'Past year'
}

export {
  scrapeTime,
  siteToScrape,
  planType,
  ERRORS
}