function convertToLocaleTime(dateString) {
  // Parse the date string
  var date = new Date(dateString);
  
  // Convert to locale time string
  var localTime = date.toLocaleString();
  
  // Return the result
  return localTime;
}

export {
  convertToLocaleTime
}